import React from "react";
import { Box, Typography, Link } from "@mui/material";

const Footer: React.FC = () => {
  return (
    <Box sx={{ mt: 5, py: 3, backgroundColor: "#f7f7f7" }}>
      <Typography variant="h6" align="center" gutterBottom>
        Contact
      </Typography>
      <Box display="flex" justifyContent="center" gap={2}>
        <Link
          sx={{
            textDecoration: "none",
            color: "primary.main",
            "&:hover": { textDecoration: "underline" },
          }}
          href="https://www.linkedin.com/in/gavinostheimer/"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </Link>
        <Link
          sx={{
            textDecoration: "none",
            color: "primary.main",
            "&:hover": { textDecoration: "underline" },
          }}
          href="mailto:gavinostheimer00@gmail.com"
        >
          Email
        </Link>
        <Link
          sx={{
            textDecoration: "none",
            color: "primary.main",
            "&:hover": { textDecoration: "underline" },
          }}
          href="/Gavin-Ostheimer-Resume.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Resume
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
