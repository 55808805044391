import React from "react";
import {
  Container,
  Typography,
  Avatar,
  Box,
  Link,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Navbar from "./Navbar";
import Footer from "./Footer";

const About: React.FC = () => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Navbar />
      <Container style={{ flex: 1, marginTop: "20px" }}>
        <Box
          sx={{
            padding: "2rem",
            backgroundColor: "#f4f6f8",
            borderRadius: "10px",
            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            marginBottom: "20px",
          }}
        >
          <Typography variant="h2" gutterBottom align="center">
            About Me
          </Typography>

          <Box display="flex" justifyContent="center" my={3}>
            <Avatar
              src="/forest_image.jpg"
              alt="Gavin Ostheimer"
              sx={{ width: 360, height: 360 }}
            />
          </Box>

          <Typography variant="h5" paragraph>
            Hello! I'm Gavin, an aspiring Product Manager and Software Developer
            with a passion for working on technology and delivering products
            that exceed customer expectations.
          </Typography>

          <Typography variant="h5">
            When I'm not at work, you can find me reading, watching the latest
            shows, and playing games online with my brother.
          </Typography>
        </Box>

        <Box
          sx={{
            padding: "2rem",
            backgroundColor: "#f4f6f8",
            borderRadius: "10px",
            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            marginBottom: "20px",
          }}
        >
          <Typography variant="h4" gutterBottom>
            What I Like
          </Typography>
          <List>
            <ListItem disablePadding>
              <ListItemText>
                <Typography variant="h6">
                  <Link
                    sx={{
                      textDecoration: "none",
                      color: "primary.main",
                      "&:hover": { textDecoration: "underline" },
                    }}
                    href="https://funko.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Collecting Funkos
                  </Link>
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem disablePadding>
              <ListItemText>
                <Typography variant="h6">
                  <Link
                    sx={{
                      textDecoration: "none",
                      color: "primary.main",
                      "&:hover": { textDecoration: "underline" },
                    }}
                    href="https://sneakernews.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Keeping up on the latest shoes
                  </Link>
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem disablePadding>
              <ListItemText>
                <Typography variant="h6">
                  <Link
                    sx={{
                      textDecoration: "none",
                      color: "primary.main",
                      "&:hover": { textDecoration: "underline" },
                    }}
                    href="https://www.wizardingworld.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Staying up to date with all things Harry Potter
                  </Link>
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Box>

        <Box
          sx={{
            padding: "2rem",
            backgroundColor: "#f4f6f8",
            borderRadius: "10px",
            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
          }}
        >
          <Typography variant="h4" gutterBottom>
            What I'm Learning
          </Typography>
          <Typography variant="h6">Bouldering</Typography>
          <Typography variant="h6">How to increase WPM</Typography>
          <Typography variant="h6">
            How to make the best grilled cheese
          </Typography>
        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default About;
