import React from "react";
import {
  Container,
  Link,
  Typography,
  Box,
  Paper,
  Button,
  Grid,
  Avatar,
} from "@mui/material";
import { GitHub } from "@mui/icons-material";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Home: React.FC = () => {
  return (
    <div>
      <Navbar />
      <Container>
        <Box sx={{ my: 4, textAlign: "center" }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Avatar
                src="suit_image.jpg"
                alt="Gavin Ostheimer"
                sx={{ width: 300, height: 300, mx: "auto", display: "block" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h2" component="h1" gutterBottom>
                Welcome!
              </Typography>
              <Typography variant="h4" component="h2" gutterBottom>
                I'm Gavin, an Integrated B.S./M.S. in Information Systems
                Management student at BYU
              </Typography>
            </Grid>
          </Grid>

          <Paper elevation={3} sx={{ my: 4, p: 4 }}>
            <Typography variant="h4" component="h2" gutterBottom>
              Founder, Product Manager, and Software Developer at SolePredict
            </Typography>
            <Typography variant="body1">
              Constructed a random forest model to value upcoming sneaker
              releases · Built an extensive database of sneakers for the
              prediction model to leverage · Designed a react front-end website
              for customers to visit (solepredict.com) · Deployed the
              application, domain, DynamoDB table, and S3 bucket on AWS
              <br />
              <br />
              <Link
                href="https://solepredict.com"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  textDecoration: "none",
                  color: "primary.main",
                  "&:hover": { textDecoration: "underline" },
                  fontSize: "1.3rem",
                }}
              >
                View Site
              </Link>
            </Typography>
            <img
              src="/solepredict-align-right (1).png"
              alt="SolePredict"
              style={{ width: "100%", maxWidth: "400px", marginTop: "20px" }}
            />
          </Paper>

          <Paper elevation={3} sx={{ my: 4, p: 4 }}>
            <Typography variant="h4" component="h2" gutterBottom>
              BYU OIT Full-Stack Developer
            </Typography>
            <Typography variant="body1">
              Developed BYU-Idaho's first version admission application,
              enhancing CES School admission software continuity by 20% ·
              Engineered the enhanced front-end for the BYU Honors College's
              website, increasing staff technical efficiency by 30% · Contribute
              thousands of lines of production code to BYU · Work effectively in
              a scrum group
              <br />
              <br />
              <Link
                href="https://application.byui.edu/"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  textDecoration: "none",
                  color: "primary.main",
                  "&:hover": { textDecoration: "underline" },
                  fontSize: "1.3rem",
                }}
              >
                View BYU-Idaho Application
              </Link>
            </Typography>
            <img
              src="/byu_oit.png"
              alt="BYU OIT"
              style={{ width: "100%", maxWidth: "400px", marginTop: "20px" }}
            />
          </Paper>

          <Paper elevation={3} sx={{ my: 4, p: 4 }}>
            <Typography variant="h4" component="h2" gutterBottom>
              Accountant at the BYU Law Library
            </Typography>
            <Typography variant="body1">
              Processed Invoices · Adjusted budget amounts for the Law Library ·
              Delivered reimbursements to staff, students, and faculty ·
              Processed time management for the Law School · Recorded all Law
              Library subscriptions on an annual basis
            </Typography>
            <img
              src="/BYU-Law-1-1.png"
              alt="BYU Law Library"
              style={{ width: "100%", maxWidth: "300px", marginTop: "20px" }}
            />
          </Paper>

          <Paper elevation={3} sx={{ my: 4, p: 4 }}>
            <Typography variant="h4" component="h2" gutterBottom>
              Personal Projects
            </Typography>
            <Typography variant="body1">
              My personal code projects are kept on my GitHub repository linked
              below
            </Typography>
            <br />
            <Button
              variant="outlined"
              startIcon={<GitHub />}
              href="https://github.com/gavin3567"
              target="_blank"
              rel="noopener noreferrer"
            >
              View on GitHub
            </Button>
          </Paper>
        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default Home;
